<template>
  <download-excel
    :class="eClass"
    :fetch="fetchFile"
    :fields="fileFields"
    :before-generate="fileStart"
    :before-finish="fileFinish"
    :name="fileName + '.' + ext"
    :title="fileTitle"
    :worksheet="worksheetTitle"
    :type="ext"
  >
    <span :title="'Download Members (' + ext.toUpperCase() + ')'" v-html="btnLabel"></span>
  </download-excel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'MembersExport',
  props: {
    params: {
      type: Object,
      default () { return {} }
    },
    eClass: {
      type: String,
      default () { return 'btn btn-light' }
    },
    btnLabel: {
      type: String,
      default () { return '<i class="fa fa-download"></i>' }
    },
    ext: {
      type: String,
      default () { return 'xls' }
    }
  },
  data () {
    let vm = this
    return {
      fileName: 'nzs-members',
      fileTitle: 'Members',
      fileFields: {
        'UID': 'id',
        'Name': 'fullName',
        'Phone': 'phone',
        'Email': 'email',
        'Zip Code': 'zipCode',
        'Registrations': {
          field: 'registeredEvents',
          callback (registeredEvents) {
            return registeredEvents.length
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'userList'
    ]),
    worksheetTitle () {
      return this.fileTitle
    }
  },
  methods: {
    ...mapActions([
      'getUsers'
    ]),
    setFileField (label, key, index) {
      // this.fileFields[label] = key
      // console.log(label, key)
      // Create a temp object and index variable
      var obj = { ...this.fileFields }
      var temp = {}
      var i = 0
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          if (i === index && key && label) {
            temp[label] = key
          }
          temp[prop] = obj[prop]

          i++
        }
      }
      if (!index && key && label) {
        temp[label] = key
      }
      this.fileFields = temp
    },
    async fetchFile () {
      this.showMessage({ title: 'Loading', message: 'Just a moment while we load the data...' })

      // get users
      let params = this.params || {
        active: 1
      }
      params.active = 1
      params.businesses = this.currentBusiness.id
      params.pagination = false
      // params.roles = 'role_member'
      await this.getUsers(params)

      // console.log('ret: ', this.userList)
      return this.userList
    },
    fileStart () {},
    fileFinish () {}
  }
}
</script>